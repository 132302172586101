import React from 'react'

type WhichToolWithDataEntryPointProps = {
  toolName: string
  xmlFileSize: number
  xmlFilePath: string
}

export const WhichToolWithDataEntryPoint = ({
  toolName,
  xmlFileSize,
  xmlFilePath,
}: WhichToolWithDataEntryPointProps) => {
  if (!toolName || !xmlFileSize || !xmlFilePath) {
    return null
  }

  // TODO: Some sort of mapping to future migrated XML data tools in here using toolName [Implement Glide Driven XML data - Ticket TBC]
  // Passing the xmlFilePath as a prop to use as data source
  // Path would need to be prefixed with MEDIA_BASE_PATH env var
  // Also some sort of safeguarding against huge XML files using the xmlFileSize prop?

  return (
    <>
      <p>WhichToolWithData</p>
      <p>Tool name: {toolName}</p>
      <p>File Size: {xmlFileSize}</p>
      <p>File Path: {xmlFilePath}</p>
    </>
  )
}
