import type { FunctionComponent } from 'react'
import React from 'react'
import { Callout as SBCallout } from '@which/seatbelt'

import type { CalloutItem } from '../../../generated/frontend'
import { useFullUrl } from '../../hooks/useFullUrl'

export type CalloutProps = {
  title: string
  items: CalloutItem[]
  description?: string
  byline?: string
  imageFormat?: 'rectangle' | 'square'
}

export const Callout: FunctionComponent<CalloutProps> = ({
  byline,
  description,
  imageFormat,
  items,
  title,
}) => {
  const { getFullUrl } = useFullUrl()

  if (!items?.length) {
    return null
  }

  const updatedItems = removeCurrentPageFromItems(items, getFullUrl())

  return (
    <div data-testid="callout">
      <SBCallout
        byline={byline}
        imageAspectRatio={imageFormat === 'rectangle' ? 'two-to-one' : 'one-to-one'}
        title={title}
        description={description}
        cards={updatedItems}
      />
    </div>
  )
}

const removeCurrentPageFromItems = (items: CalloutItem[], fullUrl: string): CalloutItem[] =>
  items.filter((item) => !fullUrl.includes(item.href))
