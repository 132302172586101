import React from 'react'
import { Link, MultiLinkBlock, TypographyV2 } from '@which/seatbelt'

import classnames from 'classnames'

import { groupLinksByType } from '../../../shared/utils/group-links-by-type'
import { AssociatedArticlesWithShowMore } from './AssociatedArticlesWithShowMore'

export const AssociatedArticles: React.FC<Props> = ({
  links,
  title,
  titleCategory,
  titleCategoryLink,
  seeAllLink,
  isOrdered,
  maxNumLinks = 5,
  componentType,
  position = 'bottom',
  useShowMoreComponent = false,
  className,
}) => {
  // TODO: Tidy up titleCategory and titleCategoryLink logic when tidying up WHC-812 test
  if (!links?.length || (!title && !titleCategory && !titleCategoryLink)) {
    return null
  }

  const titleLinkTracking =
    position === 'bottom' ? 'endofarticle-link-header' : 'sidebar-link-header'
  const seeMoreLinkTracking =
    position === 'bottom' ? 'endofarticle-link-footer' : 'sidebar-link-footer'

  const groupedLinks = groupLinksByType(links, { standard: maxNumLinks }) ?? []

  return useShowMoreComponent ? (
    <AssociatedArticlesWithShowMore
      links={links}
      title={title}
      isOrdered={isOrdered}
      maxNumLinks={maxNumLinks}
      componentType={componentType}
      position={position}
      className={className}
    />
  ) : (
    <div
      className={classnames('associated-articles', className)}
      data-testid="associated-article-links-wrapper"
    >
      {!title && titleCategory && titleCategoryLink && !seeAllLink && (
        <TypographyV2 textStyle="sb-text-heading-small" tag="h2">
          More on{' '}
          <Link
            href={titleCategoryLink}
            textStyle="sb-text-heading-small"
            data-which-id={titleLinkTracking}
          >
            {titleCategory}
          </Link>
        </TypographyV2>
      )}
      <MultiLinkBlock
        componentType={componentType && `${componentType}-${position}`}
        title={title}
        links={groupedLinks}
        isOrdered={isOrdered}
      />
      {seeAllLink && titleCategory && titleCategoryLink && (
        <TypographyV2 textStyle="sb-text-body-default-regular" data-testid="see-more-link">
          See all from{' '}
          <Link href={titleCategoryLink} data-which-id={seeMoreLinkTracking}>
            {titleCategory}
          </Link>
        </TypographyV2>
      )}
    </div>
  )
}

export type Props = {
  title: string
  titleCategory?: string
  titleCategoryLink?: string
  seeAllLink?: boolean
  componentType: string
  position: string
  links?: Link[]
  viewAllLink?: Link
  isOrdered?: boolean
  maxNumLinks?: number
  useShowMoreComponent?: boolean
  className?: string
}

type Link = {
  href: string
  text: string
}
