import type { FunctionComponent, ReactElement } from 'react'
import React from 'react'
import type { BadgeTheme } from '@which/seatbelt'
import { Badge, TooltipWithButton } from '@which/seatbelt'

import { ArticleTrackonomicsLink } from '../../../../pages/article/components/ArticleTrackonomicsLink'
import { Link } from '../../Link'
import styles from './RichTableCell.module.scss'

export const RichTableCell: FunctionComponent<Props> = (props) => {
  const { isTooltipDisplayedAboveOverride, tooltipAlignmentOverride, components } = props

  return (
    <div className={styles.richTableCellWrapper}>
      {components?.map((element, index) => {
        const { component, ...rest } = element

        if (rest.tooltip) {
          return (
            <div className={styles.richTableCellTooltipRow} key={`${component}-${index}`}>
              {componentMap[component](rest)}
              {componentMap[rest.tooltip.component]({
                ...rest.tooltip,
                isTooltipDisplayedAboveOverride,
                tooltipAlignmentOverride,
              })}
            </div>
          )
        }

        return (
          <div key={`${component}-${index}`}>
            {componentMap[component]({
              ...rest,
              isTooltipDisplayedAboveOverride,
              tooltipAlignmentOverride,
            })}
          </div>
        )
      })}
    </div>
  )
}

///////// IMPLEMENTATION /////////

interface Component {
  component: 'Badge' | 'Text' | 'Tooltip' | 'Link' | 'ArticleTrackonomicsLink'
  theme?: BadgeTheme
  backgroundColor?: 'teal'
  variant?: boolean
  text?: string
  value?: string
  url?: string
  label?: string
  tooltip?: Component
  tracking?: Record<string, string>
  target?: string
  rel?: string
}

interface Props {
  sortableValue: string
  components: Component[]
  isTooltipDisplayedAboveOverride: boolean
  tooltipAlignmentOverride: 'left' | 'right'
}

const componentMap: Record<
  Component['component'],
  (allProps: Partial<Component & Props>) => ReactElement<Component>
> = {
  Badge: (element) => {
    const isEcoRecommendedProvider = element?.text?.toLowerCase() === 'eco provider'
    const isRecommendedProvider = element?.text?.toLowerCase() === 'recommended provider'
    const isRecommendedBrand = element?.text?.toLowerCase() === 'recommended brand'

    if (isEcoRecommendedProvider) {
      element.theme = 'eco buy'
    }

    if (isRecommendedProvider || isRecommendedBrand) {
      element.theme = 'best buy'
    }

    const isRecommendedBadge = isEcoRecommendedProvider || !element.theme

    return (
      <Badge
        className={
          isRecommendedBadge ? styles.richTableCellRecommendedBadge : styles.richTableCellBadge
        }
        {...element}
      />
    )
  },
  Link: ({ label, url, rel, target }) => (
    <Link href={url} className={styles.richTableCellLink} rel={rel} target={target}>
      {label}
    </Link>
  ),
  ArticleTrackonomicsLink: ({ label, url, tracking }) => (
    <ArticleTrackonomicsLink href={url ?? ''} contentType="article" optionalTracking={tracking}>
      {label}
    </ArticleTrackonomicsLink>
  ),
  Tooltip: ({ value, isTooltipDisplayedAboveOverride, tooltipAlignmentOverride }) => (
    <TooltipWithButton
      contents={value ?? ''}
      ariaLabel={value ?? ''}
      tooltipAlignmentOverride={tooltipAlignmentOverride}
      isTooltipDisplayedAboveOverride={isTooltipDisplayedAboveOverride}
    />
  ),
  Text: ({ value }) => <>{value}</>,
}
