import type { FunctionComponent } from 'react'
import { useEffect, useMemo, useState } from 'react'
import { getCookie } from '@which/shared'
import { gql, useQuery } from '@apollo/client'

import { useFeatureIsOn } from '@growthbook/growthbook-react'
import { type JWTDecoded, jwtDecoder } from '@kinde/jwt-decoder'
import { useKindeAuth } from '@kinde-oss/kinde-auth-react'
import loadable from '@loadable/component'
import otel from '@opentelemetry/api'

import { useSavedItemsQuery } from '../../generated/frontend'
import { isLocal } from '../../shared'
import { Loader } from '../../shared/components/Loader'
import { PagePropsContext } from '../../shared/usePageProps'
import type { PageProps } from '../'

const useKindeOnClient = () => {
  const { isAuthenticated, isLoading, getAccessToken } = useKindeAuth()
  const [isClient, setIsClient] = useState(false)
  useEffect(() => {
    setIsClient(true)
  }, [])

  return isClient
    ? { isAuthenticated, isLoading, getAccessToken }
    : {
        isAuthenticated: false,
        isLoading: true,
        getAccessToken: async () => {},
      }
}

export const Page: FunctionComponent<PageProps> = ({
  pagePath,
  template,
  rootCollectionName,
  context,
}) => {
  const MatchedPage = useMemo(
    () =>
      loadable(
        () =>
          import(
            /* webpackInclude: /[a-zA-Z\-]*\/[a-zA-Z]*Page\.tsx$/ */ `../../pages/${pagePath}`
          ),
        {
          fallback: <Loader />,
        }
      ),
    [pagePath]
  )

  const { isAuthenticated: isKindeAuthenticated, isLoading, getAccessToken } = useKindeOnClient()

  const kindeLoginFeature = useFeatureIsOn('paywall-kinde-login')
  const { data: { userAccessState } = {} } = useQuery(USER_ACCESS_STATE_QUERY)

  const isPaidMember = ['AUTHENTICATED_FULL_ACCESS', 'FULL_ACCESS'].includes(
    userAccessState?.transformTypeDecision ?? ''
  )

  // We want to fetch saved products only for below templates - add more template names when we want to expand it to other areas of the website
  const isAllowedPageTemplate = [
    'Reviews Product Listing',
    'Reviews Product Page',
    'Reviews Product Comparison',
    'Article Advice Multi Page',
    'Article Advice Single Page',
  ].includes(template ?? '')
  const skipFetchingSavedItems = !isPaidMember || !isAllowedPageTemplate

  const { data } = useSavedItemsQuery({
    fetchPolicy: 'cache-and-network',
    skip: skipFetchingSavedItems,
  })
  const savedItems = data?.savedItems ?? []

  if (typeof otel !== 'undefined') {
    const span = otel.trace.getSpan(otel.context.active())
    span?.updateName(pagePath)
  }

  useEffect(() => {
    const blaizeCookie = getCookie(document.cookie, 'blaize_session')

    const domain = isLocal() ? 'localhost' : '.which.co.uk'
    const clearCookies = () => {
      document.cookie = `blaize_session=;expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/;domain=${domain}`
      document.cookie = `sessionId=;expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/;domain=${domain}`
    }

    const setCookies = async () => {
      type ExtendedJWTDecoded = JWTDecoded & { salesforceId?: string }
      const kindeAccessToken = await getAccessToken()
      const decoded = jwtDecoder(kindeAccessToken ?? '') as ExtendedJWTDecoded
      const salesforceId = decoded?.salesforceId || ''
      const date = new Date()
      date.setMonth(date.getMonth() + 1)
      const expireDate = date.toUTCString()
      document.cookie = `sessionId=${kindeAccessToken};path=/; domain=${domain};expires=${expireDate}`
      document.cookie = `blaize_session=${kindeAccessToken};path=/; domain=${domain};expires=${expireDate}`
      document.cookie = `master_id.analytics_flag=3;path=/; domain=${domain};expires=${expireDate}`
      document.cookie = `master_id=${salesforceId};path=/; domain=${domain};expires=${expireDate}`
    }

    const tempSession = blaizeCookie === 'temp'
    const kindeQuery = new URLSearchParams(window.location.search)
    const kindeQueryCode =
      kindeQuery.get('code') && kindeQuery.get('state') && kindeQuery.get('scope')

    const checkCookieState = async () => {
      if (!isLoading && isKindeAuthenticated && (!blaizeCookie || (tempSession && blaizeCookie))) {
        await setCookies()

        const kindeRedirect = sessionStorage.getItem('kindeRedirect')
        if (kindeRedirect) {
          sessionStorage.removeItem('kindeRedirect')
          window.location.replace(kindeRedirect)
        } else {
          location.reload()
        }
      } else if (!isLoading && !isKindeAuthenticated && kindeQueryCode) {
        await setCookies()
        const kindeRedirect = sessionStorage.getItem('kindeRedirect')
        if (kindeRedirect) {
          sessionStorage.removeItem('kindeRedirect')
          window.location.replace(kindeRedirect)
        } else {
          window.history.replaceState({}, document.title, window.location.pathname)
        }
      } else if (blaizeCookie && tempSession && isLoading && kindeLoginFeature) {
        // don't clear cookies or reload the page while kinde is authenticating
      } else if (
        blaizeCookie &&
        userAccessState &&
        userAccessState.transformTypeDecision === 'ANONYMOUS_NO_ACCESS'
      ) {
        clearCookies()
        location.reload()
      }
    }
    checkCookieState()
  }, [userAccessState, isKindeAuthenticated, kindeLoginFeature, getAccessToken, isLoading])

  return (
    <PagePropsContext.Provider
      value={{
        template,
        rootCollectionName,
        context,
        userAccessState,
        savedItems,
      }}
    >
      <MatchedPage />
    </PagePropsContext.Provider>
  )
}

export const USER_ACCESS_STATE_QUERY = gql`
  query userAccessState {
    userAccessState {
      userLoggedIn
      transformTypeDecision
    }
  }
`
