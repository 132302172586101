import React from 'react'
import type { PageContext } from '@which/glide-ts-types'

import { useFeatureIsOn } from '@growthbook/growthbook-react'

import type { WCDRouteProps } from '.'
import { Page } from './components/Page'

const ReviewsAdviceSinglePage: React.FC<{ context: PageContext }> = ({ context }) => {
  const enableReviewsSingleAdviceArticleNoTemplate = useFeatureIsOn(
    'enable-reviews-singlepage-advice-article-no-template'
  )

  return enableReviewsSingleAdviceArticleNoTemplate ? (
    <Page pagePath="article/ReviewsAdviceSinglePage" context={context} />
  ) : (
    <Page pagePath="article/ArticlePage" template="Article Advice Single Page" context={context} />
  )
}

const ReviewsAdviceMultiPage: React.FC<{ context: PageContext }> = ({ context }) => {
  const enableReviewsMultiAdviceArticleNoTemplate = useFeatureIsOn(
    'enable-reviews-multipage-advice-article-no-template'
  )

  return enableReviewsMultiAdviceArticleNoTemplate ? (
    <Page pagePath="article/ReviewsAdviceMultiPage" context={context} />
  ) : (
    <Page pagePath="article/ArticlePage" template="Article Advice Multi Page" context={context} />
  )
}

const ConsumerRightsPage: React.FC = () => {
  const enableConsumerRightsArticleNoTemplate = useFeatureIsOn(
    'enable-consumer-rights-article-no-template'
  )

  return enableConsumerRightsArticleNoTemplate ? (
    <Page pagePath="article/ConsumerRightsPage" context="consumer-rights" />
  ) : (
    <Page
      pagePath="article/ArticlePage"
      template="Article CR Single Page"
      context="consumer-rights"
    />
  )
}

const ConsumerRightsCarToolArticle: React.FC = () => {
  const enableCRCarToolArticleNoTemplate = useFeatureIsOn(
    'enable-consumer-rights-car-tool-article-no-template'
  )

  return enableCRCarToolArticleNoTemplate ? (
    <Page pagePath="article/ConsumerRightsCarAdviceToolPage" context="consumer-rights" />
  ) : (
    <Page pagePath="article/ArticlePage" template="CR Tool Article" context="consumer-rights" />
  )
}

const ConsumerRightsScamsArticle: React.FC = () => {
  const enableCRScamsArticleNoTemplate = useFeatureIsOn(
    'enable-consumer-rights-scams-article-no-template'
  )

  return enableCRScamsArticleNoTemplate ? (
    <Page pagePath="article/ConsumerRightsScamsPage" context="consumer-rights" />
  ) : (
    <Page pagePath="article/ArticlePage" template="CR Scams Article" context="consumer-rights" />
  )
}

const PIArticle: React.FC = () => {
  const enablePiArticleDeliverTemplate = useFeatureIsOn('pi-article-deliver-template')

  return enablePiArticleDeliverTemplate ? (
    <Page pagePath="article/PIArticlePage" context="policy-and-insight" />
  ) : (
    <Page
      pagePath="article/ArticlePage"
      template="Article Policy and Insight Single Page"
      context="policy-and-insight"
    />
  )
}

const NewsArticle: React.FC = () => {
  const enableNewsArticleDeliverTemplate = useFeatureIsOn('news-article-deliver-template')

  return enableNewsArticleDeliverTemplate ? (
    <Page pagePath="article/NewsArticlePage" context="news" />
  ) : (
    <Page pagePath="article/ArticlePage" template="Article News Single Page" context="news" />
  )
}

const AboutWhichArticle: React.FC = () => {
  const enableAboutWhichArticleDeliverTemplate = useFeatureIsOn(
    'about-which-article-deliver-template'
  )

  return enableAboutWhichArticleDeliverTemplate ? (
    <Page pagePath="article/AboutWhichArticlePage" />
  ) : (
    <Page pagePath="article/ArticlePage" template="Article About Which" />
  )
}

const PensionArticle: React.FC = () => {
  const enablePensionArticleDeliverTemplate = useFeatureIsOn('pension-article-deliver-template')

  return enablePensionArticleDeliverTemplate ? (
    <Page pagePath="article/PensionArticlePage" />
  ) : (
    <Page pagePath="article/ArticlePage" template="Article About Which" />
  )
}

const articleSlug = ':articleSlug(.*-[a-zA-Z0-9]{12})'

export const articlePageRoutes: WCDRouteProps = [
  {
    live: true,
    exact: true,
    path: `/:verticalSlug(about-which|help)/${articleSlug}`,
    component: () => <AboutWhichArticle />,
  },
  {
    live: true,
    exact: true,
    path: `/:verticalSlug(consumer-rights)/:articleType(advice)/:articleSlug(the-car-i-bought-has-a-problem-what-are-my-rights-[a-zA-Z0-9]{12})`,
    component: () => <ConsumerRightsCarToolArticle />,
  },
  {
    live: true,
    exact: true,
    path: [
      `/:verticalSlug(consumer-rights)/:articleType(advice)/:articleSlug(how-to-get-your-money-back-after-a-scam-[a-zA-Z0-9]{12})`,
      `/:verticalSlug(consumer-rights)/:articleType(advice)/:articleSlug(how-to-spot-a-scam-[a-zA-Z0-9]{12})`,
      `/:verticalSlug(consumer-rights)/:articleType(advice)/:articleSlug(how-to-report-a-scam-[a-zA-Z0-9]{12})`,
    ],
    component: () => <ConsumerRightsScamsArticle />,
  },
  {
    live: true,
    exact: true,
    path: `/:verticalSlug(consumer-rights)/:articleType(advice|letter|regulation)/${articleSlug}`,
    component: () => <ConsumerRightsPage />,
  },
  {
    live: true,
    exact: true,
    path: `/:verticalSlug(money)/:subVerticalSlug/:categorySlug/:collectionSlug/${articleSlug}`,
    component: () => <ReviewsAdviceMultiPage context="money" />,
  },
  {
    live: true,
    exact: true,
    path: `/:verticalSlug(money)/:subVerticalSlug/:categorySlug/${articleSlug}`,
    component: () => <ReviewsAdviceSinglePage context="money" />,
  },
  {
    live: true,
    exact: true,
    path: [`/news/:year([0-9]{4})/:month([0-9]{2})/${articleSlug}`, `/news/article/${articleSlug}`],
    component: () => <NewsArticle />,
  },
  {
    live: true,
    exact: true,
    path: `/:verticalSlug(pension-scheme)/${articleSlug}`,
    component: () => <PensionArticle />,
  },
  {
    live: true,
    exact: true,
    path: `/policy-and-insight/article/${articleSlug}`,
    component: () => <PIArticle />,
  },
  {
    live: true,
    exact: true,
    path: `/reviews/:superCategorySlug/article/:collectionSlug/${articleSlug}`,
    component: () => <ReviewsAdviceMultiPage context="reviews" />,
  },
  {
    live: true,
    exact: true,
    path: `/reviews/:superCategorySlug/article/${articleSlug}`,
    component: () => <ReviewsAdviceSinglePage context="reviews" />,
  },
  {
    live: true,
    exact: true,
    path: '/free-account-exclusive',
    component: () => (
      <Page
        pagePath="article/ArticlePage"
        template="Article Advice Single Page"
        context="free-account-exclusive"
      />
    ),
  },
]
